/* eslint-disable react/no-unknown-property */
import PropTypes from "prop-types";
import { Eye, EyeSlash } from "iconsax-react";
import { Fragment, useState } from "react";
import { NumericFormat, PatternFormat } from "react-number-format";

import { removeThousandFormat } from "@/core/helpers/format-digit";

/**
 * 
 * @param {{
* variant: 'bordered' | 'contained';
* }} props 
* @returns 
*/

const InputText = (props) => {
  const {
    allowNegative = true,
    allowLeadingZeros = true,
    classContainer = `${props?.variant === 'bordered' ? `border rounded-lg` : `border-b-[1px]`} ${(props?.error || props?.errorMessage) ? 'border-danger-40' : props?.value ? 'border-success-50' : 'border-neutral-30'} flex ${(props?.leftSpanBorder || props?.rightSpanBorder) ? '' : 'gap-2'} ${props?.disabled ? 'bg-neutral-10' : ''} items-center overflow-hidden`,
    classContainerAdditional = '',
    classGroup = `relative`,
    classLabel = "leading-[16px] py-1 text-[12px] text-neutral-90 tracking-[0.4px]",
    classLabelAdditional = "",
    className = `bg-transparent leading-[20px] outline-none ${props?.variant === 'bordered' ? props?.leftSpan ? 'pr-4' : props?.rightSpan ? 'pl-4' : 'px-4' : ''} py-[14px] text-[14px] text-neutral-90 tracking-[0.25px] w-full`,
    classNameAdditional = "",
    errorMessage = '',
    defaultValue = '',
    disabled = false,
    dynamicContent = '',
    hideTogglePassword = false,
    label = '',
    leftSpan,
    leftSpanBorder = true,
    maxLength = 100,
    maxValue = null,
    minValue = 0,
    name = '',
    onBlur = () => { },
    onChange = () => { },
    onValueChange = () => { },
    reff = null,
    rightSpan,
    rightSpanBorder = true,
    styleContainer,
    styleLabel,
    type = 'text',
    ...rest
  } = props;

  const [getShowPassword, setShowPassword] = useState(false);

  const ReturnBlur = (e) => {
    const result = {
      value: removeThousandFormat(e?.target?.value),
    };

    onBlur && onBlur(result, e);
  }

  const ReturnValueChange = (values, e) => {
    onValueChange && onValueChange(values, e);
  }

  const ReturnValuePhone = (values, e) => {
    values = {
      formattedValue: (values?.formattedValue === '0' || values?.formattedValue === '62') ? '' : values?.formattedValue,
      value: (values?.value === '0' || values?.value === '62') ? '' : values?.value,
      floatValue: (values?.floatValue === 0 || values?.floatValue === 62) ? undefined : values?.floatValue,
    }

    onValueChange && onValueChange(values, e);
  }

  const ReturnChange = (e) => {
    onChange && onChange(e);
  }

  return (
    <div className={classGroup} style={{ opacity: disabled ? 0.4 : 1 }}>
      {label !== '' ?
        <p className={[classLabel, classLabelAdditional].join(' ')} style={styleLabel}>{label}
          {props?.required && <span className="text-danger-40">*</span>}
        </p>
        : null
      }
      <div className={`${classContainer} ${classContainerAdditional}`} dynamicContent={dynamicContent} style={styleContainer}>
        {leftSpan &&
          <span className={`${leftSpanBorder ? 'border-neutral-30 border-r-[3px] pr-[16px]' : ''} ${props?.variant === 'bordered' ? 'pl-4' : ''} font-bold leading-[20px] text-[14px] text-neutral-60 tracking-[0.25px]`}>{typeof leftSpan === 'function' ? <props.leftSpan /> : leftSpan}</span>
        }
        {type?.toLowerCase() === 'cc' ?
          <PatternFormat
            allowEmptyFormatting={true}
            allowLeadingZeros={true}
            allowNegative={allowNegative}
            className={`${className} ${classNameAdditional}`}
            defaultValue={defaultValue}
            displayType='input'
            disabled={disabled}
            format="#### #### #### ####"
            getInputRef={reff}
            name={name}
            maxLength={maxLength}
            valueIsNumericString={true}
            onBlur={ReturnBlur}
            onValueChange={ReturnValueChange}
            {...rest}
          />
          : type?.toLowerCase() === 'price' ?
            <NumericFormat
              getInputRef={reff}
              allowLeadingZeros={false}
              allowNegative={allowNegative}
              className={`${className} ${classNameAdditional}`}
              defaultValue={defaultValue}
              displayType='input'
              disabled={disabled}
              decimalScale={0}
              decimalSeparator=','
              name={name}
              thousandsGroupStyle='thousand'
              thousandSeparator='.'
              maxLength={maxLength}
              valueIsNumericString={true}
              isAllowed={values => {
                if (maxValue) return (values?.value >= minValue && values?.value <= maxValue) || values?.value === '';
                return values?.value >= minValue || values?.value === '';
              }}
              onBlur={ReturnBlur}
              onValueChange={ReturnValueChange}
              {...rest}
            />
            : type?.toLowerCase() === 'number' ?
              <NumericFormat
                getInputRef={reff}
                allowLeadingZeros={allowLeadingZeros}
                allowNegative={allowNegative}
                className={`${className} ${classNameAdditional}`}
                defaultValue={defaultValue}
                decimalScale={0}
                displayType='input'
                disabled={disabled}
                maxLength={maxLength}
                name={name}
                valueIsNumericString={true}
                isAllowed={values => {
                  if (maxValue) return (values?.value >= minValue && values?.value <= maxValue) || values?.value === '';
                  return true;
                }}
                onBlur={onBlur}
                onValueChange={ReturnValueChange}
                {...rest}
              />
              : type?.toLowerCase() === 'phone' ?
                <NumericFormat
                  getInputRef={reff}
                  allowNegative={allowNegative}
                  allowLeadingZeros={false}
                  className={`${className} ${classNameAdditional}`}
                  defaultValue={defaultValue}
                  decimalScale={0}
                  displayType='input'
                  disabled={disabled}
                  maxLength={maxLength}
                  name={name}
                  valueIsNumericString={true}
                  onBlur={onBlur}
                  onValueChange={ReturnValuePhone}
                  {...rest}
                />
                : type?.toLowerCase() === 'decimal' ?
                  <NumericFormat
                    getInputRef={reff}
                    allowNegative={allowNegative}
                    allowLeadingZeros={false}
                    className={`${className} ${classNameAdditional}`}
                    defaultValue={defaultValue}
                    displayType='input'
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=''
                    disabled={disabled}
                    maxLength={maxLength}
                    name={name}
                    valueIsNumericString={true}
                    isAllowed={values => {
                      if (maxValue) return (values?.value >= minValue && values?.value <= maxValue) || values?.value === '';
                      return true;
                    }}
                    onBlur={onBlur}
                    onValueChange={ReturnValueChange}
                    {...rest}
                  />
                  :
                  type?.toLowerCase() === 'percentage' ?
                    <NumericFormat
                      getInputRef={reff}
                      allowNegative={allowNegative}
                      allowLeadingZeros={false}
                      className={`${className} ${classNameAdditional}`}
                      defaultValue={defaultValue}
                      displayType='input'
                      disabled={disabled}
                      decimalSeparator="."
                      decimalScale={2}
                      name={name}
                      thousandSeparator=''
                      isAllowed={values => {
                        return (values?.value >= minValue && values?.value <= (maxValue ?? 100)) || values?.value === '';
                      }}
                      onBlur={onBlur}
                      onValueChange={ReturnValueChange}
                      {...rest}
                    />
                    :
                    <Fragment>
                      <input ref={reff} type={type?.toLowerCase() === 'password' ? getShowPassword ? 'text' : 'password' : type} className={`${className} ${classNameAdditional}`} defaultValue={defaultValue} maxLength={maxLength} name={name} disabled={disabled} onChange={ReturnChange} onBlur={onBlur} {...rest} onInvalid={e => console.log(e)} />
                      {(type?.toLowerCase() === 'password' && !hideTogglePassword) ?
                        <button type="button" className={`${rightSpanBorder ? 'border-neutral-30 border-l-[3px] pl-[16px]' : ''} ${props?.variant === 'bordered' ? 'pr-4' : ''} font-bold leading-[20px] text-[14px] text-neutral-60 tracking-[0.25px]`} onClick={() => setShowPassword(prevState => !prevState)}>
                          {getShowPassword ?
                            <EyeSlash className='h-6 w-6' color='#25253F' variant='Outline' />
                            :
                            <Eye className='h-6 w-6' color='#25253F' variant='Outline' />
                          }
                        </button>
                        : null
                      }
                    </Fragment>
        }
        {rightSpan &&
          <span className={`${rightSpanBorder ? 'border-neutral-30 border-l-[3px] pl-[16px]' : ''} ${props?.variant === 'bordered' ? 'pr-4' : ''} font-bold leading-[20px] text-[14px] text-neutral-60 tracking-[0.25px]`}>{typeof rightSpan === 'function' ? <props.rightSpan /> : rightSpan}</span>
        }
      </div>
      {errorMessage ?
        <p className={`mt-2 text-danger-40 text-xs`}>{errorMessage}</p>
        : null
      }
    </div>
  )
}

InputText.propTypes = {
  allowNegative: PropTypes.bool,
  allowLeadingZeros: PropTypes.bool,
  classContainer: PropTypes.string,
  classContainerAdditional: PropTypes.string,
  classGroup: PropTypes.string,
  classLabel: PropTypes.string,
  className: PropTypes.string,
  classNameAdditional: PropTypes.string,
  errorMessage: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hideTogglePassword: PropTypes.bool,
  label: PropTypes.string,
  leftSpan: PropTypes.any,
  leftSpanBorder: PropTypes.bool,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  reff: PropTypes.object,
  required: PropTypes.bool,
  rightSpan: PropTypes.any,
  rightSpanBorder: PropTypes.bool,
  styleContainer: PropTypes.string,
  styleLabel: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf([
    'blank',
    'bordered'
  ]),
  value: PropTypes.any,
};

export default InputText;
